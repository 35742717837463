export interface IResponse {
    data: IEsmCategory[];
}

export interface IEsmCategory {
    esmCategoryStatus: EsmMasterStatusEnum;
    esmCategoryId: number;
    esmCategoryName: string;
    esmMasterList: IEsmMasterData[];
    isCustom: boolean;
}

export interface IEsmMasterData {
    esmMasterGuid: number;
    esmMasterStatus: EsmMasterStatusEnum;
    esmMasterName: string;
    esmMasterAuditDate: Date;
    esmList: IEsmDTO[];
    allDefects: IEsmDefectDTO[]; // ui only
    statusClass?: string; // ui only
}

export interface IEsmDTO {
    esmGuid: string;
    id: number;
    esmStatusId: EsmStatusNumberEnum;
    frequency: number;
    esm: string;
    evidences: IESMEvidenceDTO[];
    esmMasterGuid: number;
    dueDate: Date;
    auditDate: Date;
    esmDefects: IEsmDefectDTO[];
    statusClass?: string; // ui only
    esmStatusName?: string; // ui only
    frequencyName?: string; // ui only
}

export interface IEsmDefectDTO {
    defectGuid: string;
    isCritical: boolean;
    isComplete: boolean;
    auditDate: Date;
    completionDate?: Date;
    evidences: IESMEvidenceDTO[];
    description: string;
    descriptionFormatted?: string; // UI only
    name?: string; // UI only
}

export interface IESMEvidenceDTO {
    documentId: number;
    documentName: string;
}

export enum DefectStatusEnum {
    Resolved = 'Resolved',
    NotResolved = 'Not resolved',
}

export enum EsmMasterStatusEnum {
    Compliant = 10,
    NotCompliant = 20
}

export enum EsmStatusNumberEnum {
    Completed = 10,
    Upcoming = 20,
    Overdue = 30,
    NotStarted = 40
}

export enum EsmStatusEnum {
    Completed = 'Completed',
    Overdue = 'Overdue',
    Upcoming = 'Upcoming',
    NotStarted = 'Not started',
}

export interface GetEsmCategoriesResponse {
    esmCategoryId: number;
    esmCategoryName: string,
    isCustom: boolean;
    esmMasterList: EsmCategoryMasterData[];
}

export interface EsmCategoryMasterData {
    esmMasterId: string,
    esmMasterName: string;
    esmMasterAuditDate: Date;
}