import { Injectable } from '@angular/core';
import { Observable, catchError, map } from 'rxjs';

import { ApiServiceBase } from '../api-service-base';
import { Tag } from '../models';
import { IAddStatementPostRequest } from '../models/typings/statements/add-statement-post-request.interface';
import { IGetAllStatementsRequest } from '../models/typings/statements/get-all-statements-request.interface';
import { IGetAllStatementsResponse } from '../models/typings/statements/get-all-statements-response.interface.';

@Injectable({
    providedIn: 'root'
})
export class StatementApiService extends ApiServiceBase {
    private _apiBase = `${this.appConfig.apiBase}/api/annualstatements`;

    getAllStatements(model: IGetAllStatementsRequest): Observable<IGetAllStatementsResponse> {
        return this.http.post<any>(`${this._apiBase}/${model.buildingGuid}`, model)
            .pipe(
                map((response) => response),
                catchError(this.handleError)
            );
    }

    addStatement(postBody: IAddStatementPostRequest): Observable<any> {
        const formData = new FormData();

        formData.append('buildingId', postBody.buildingId);
        formData.append('startDate', postBody.startDate.toISOString());
        formData.append('endDate', postBody.endDate.toISOString());
        formData.append(`evidenceFile`, postBody.evidenceFile);

        postBody.tags?.forEach((tag: Tag, index: number) => {
            formData.append(`tags[${index}].tagName`, tag.tagName);
        });

        const url = `${this._apiBase}`;
        return this.http.post<any>(url, formData)
            .pipe(
                map((response) => response),
                catchError(this.handleError)
            );
    }

}
