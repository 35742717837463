import { CommonModule } from '@angular/common';
import {
    Component,
    Inject
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';


export interface IDuplicatesDialogData {
    duplicates: string[];
}

@Component({
    selector: 'qpx-duplicates-modal',
    templateUrl: './duplicates-modal.component.html',
    styleUrls: ['./duplicates-modal.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        MatButtonModule,
        MatDialogModule,
        MatIconModule
    ],
})
export class DuplicatesModalComponent {

    constructor(
        public dialogRef: MatDialogRef<DuplicatesModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: IDuplicatesDialogData
    ) { }

    onCancel(): void {
        this.dialogRef.close();
    }
}
