<ng-container>
    <h2 mat-dialog-title>
        <mat-icon (click)="onCancel()">close</mat-icon>
    </h2>
</ng-container>

<mat-dialog-content>
    <div class="confirm-dialog-title">Duplicates Detected</div>
    <div>It looks like you're uploading documents that we already have on file</div>

    <div *ngIf="data.duplicates?.length"
         class="duplicates">
        <div class="duplicate"
             *ngFor="let duplicate of data.duplicates">- {{duplicate}}</div>
    </div>

    <div>Do you want to upload anyway?</div>
</mat-dialog-content>

<mat-dialog-actions>
    <div class="cancel-button-wrapper">
        <button mat-stroked-button
                (click)="onCancel()">
            <mat-icon class="qpx-icon-sm">
                close
            </mat-icon>
            Don't upload
        </button>
    </div>
    <button mat-flat-button
            color="accent"
            mat-dialog-close="confirm"
            cdkFocusInitial>
        Upload
    </button>

</mat-dialog-actions>