import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MointoringService } from './mointoring.service';
import { throwError } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class HandleErrorService {
    private snackBar = inject(MatSnackBar);
    private logger = inject(MointoringService);

    // Handling HTTP Errors using Toaster
    public handleError(err: HttpErrorResponse) {
        this.logger.logException(err);
        let errorMessage: string;
        if (err.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            errorMessage = `An error occurred: ${err.error.message}`;
        } else {
            // The backend returned an unsuccessful response code.
            if (err.error?.title) { // API returns an error message so use that for toast notification
                errorMessage = err.error.title;
            }
            else {
                switch (err.status) {
                    case 400:
                        if (err.error?.description) {
                            errorMessage = `${err.error.description}`;
                            break;
                        }
                        errorMessage = `${err.error.detail}`;
                        break;
                    case 401:
                        errorMessage = 'You need to log in to do this action.';
                        break;
                    case 403:
                        errorMessage = 'You are not authorized to do this action.';
                        break;
                    case 404:
                        errorMessage = `The requested resource does not exist.`;
                        break;
                    case 409:
                        errorMessage = 'There was a conflict with the exisiting resource.';
                        break;
                    case 412:
                        errorMessage = 'Precondition Failed.';
                        break;
                    case 500:
                        errorMessage = 'Internal Server Error.';
                        break;
                    case 503:
                        errorMessage = 'The requested service is not available.';
                        break;
                    case 422:
                        errorMessage = 'Validation Error!';
                        break;
                    default:
                        errorMessage = 'Something went wrong!';
                }
            }
        }
        if (errorMessage) {
            this.snackBar.open(errorMessage, 'error', {
                horizontalPosition: 'center',
                verticalPosition: 'top',
                panelClass: 'danger',
                duration: 1500,
            });
        }
        return throwError(() => errorMessage);
    }
}
