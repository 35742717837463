import { Component } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@Component({
    standalone: true,
    selector: 'qpx-loader',
    template: `<mat-spinner class="qpx-loader"></mat-spinner>`,
    styles: [`
    :host {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }`],
    imports: [MatProgressSpinnerModule]
})
export class LoaderComponent { }
