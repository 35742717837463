import { Injectable } from '@angular/core';
import { Observable, catchError, map } from 'rxjs';
import { ApiServiceBase } from '../api-service-base';
import {
    IBuildingName,
    IGetBuildingAletsResponse, IGetCultureScoresResponse,
    IGetIntegrityScoresResponse, KeyDocumentTypes
} from '../models';
import { IBuildingHandoverPostModel } from '../models/typings/building-handover-post-model.interface';
import { IBuildingListResponse } from '../models/typings/building-list-response.interface';
import { IBuildingProgress } from '../models/typings/building-progress.interface';
import { IDocumentOverview, IKeyDocument, IKeyDocumentOverview } from '../models/typings/document.interface';
import { IUploadKeyDocumentsPostModel } from '../models/typings/upload-key-documents-post-model.interface';

@Injectable({
    providedIn: 'root',
})
export class BuildingApiService extends ApiServiceBase {
    private apiBuildingBase = `${this.appConfig.apiBase}/api/buildings`;


    getBuildings(postBody: any): Observable<IBuildingListResponse> {
        const url = `${this.apiBuildingBase}/all`;
        return this.http.post<any>(url, postBody).pipe(
            map((response) => response),
            catchError(this.handleError)
        );
    }

    getBuildingNames(): Observable<IBuildingName[]> {
        const url = `${this.apiBuildingBase}/names`;
        return this.http.get<any>(url).pipe(
            map((response) => response),
            catchError(this.handleError)
        );
    }


    getBuildingIntegrityScores(sortOrder: number): Observable<IGetIntegrityScoresResponse[]> {
        const url = `${this.apiBuildingBase}/insights/integrity-scores?sortOrder=${sortOrder}`;
        return this.http.get<any>(url).pipe(
            map((response) => response),
            catchError(this.handleError)
        );
    }

    getBuildingCultureScores(sortOrder: number): Observable<IGetCultureScoresResponse[]> {
        const url = `${this.apiBuildingBase}/insights/culture-scores?sortOrder=${sortOrder}`;
        return this.http.get<any>(url).pipe(
            map((response) => response),
            catchError(this.handleError)
        );
    }

    getBuildingAlerts(model: any): Observable<IGetBuildingAletsResponse> {
        const url = `${this.apiBuildingBase}/insights/building-alerts`;
        return this.http.post<any>(url, model).pipe(
            map((response) => response),
            catchError(this.handleError)
        );
    }

    getBuildingContacts(postBody: any) {
        const url = `${this.apiBuildingBase}/${postBody.buildingId}/contacts`;
        return this.http.post<any>(url, postBody).pipe(
            map((response) => response),
            catchError(this.handleError)
        );
    }

    addUpdateBuildingContact(buildingId: string, postBody: any) {
        const url = `${this.apiBuildingBase}/${buildingId}/contact`;
        postBody.buildingId = buildingId;
        return this.http.post<any>(url, postBody).pipe(
            map((response) => response),
            catchError(this.handleError)
        );
    }

    deleteBuildingContact(buildingId: string, contactId: string) {
        const url = `${this.apiBuildingBase}/${buildingId}/contact/${contactId}`;
        return this.http.delete<any>(url).pipe(
            map((response) => response),
            catchError(this.handleError)
        );
    }

    addBuilding(postBody: any) {
        const url = `${this.apiBuildingBase}`;
        return this.http.post<any>(url, postBody).pipe(
            map((response) => response),
            catchError(this.handleError)
        );
    }

    updateBuilding(postBody: any) {
        const url = `${this.apiBuildingBase}`;
        return this.http.put<any>(url, postBody).pipe(
            map((response) => response),
            catchError(this.handleError)
        );
    }

    handoverBuilding(postBody: IBuildingHandoverPostModel) {
        const url = `${this.apiBuildingBase}/${postBody.buildingId}/handover`;
        return this.http.post<any>(url, postBody)
            .pipe(
                map((response) => response),
                catchError(this.handleError)
            );
    }

    addUpdateBuildingBookmark(postBody: any) {
        const url = `${this.apiBuildingBase}/${postBody.buildingId}/bookmark`;
        return this.http.post<any>(url, postBody).pipe(
            map((response) => response),
            catchError(this.handleError)
        );
    }
    getBuildingKeyDocuments(id: any): Observable<IKeyDocumentOverview[]> {
        const url = `${this.apiBuildingBase}/${id}/key-documents`;
        return this.http.get<any>(url)
            .pipe(
                map((response) => response),
                catchError(this.handleError)
            );
    }

    getBuildingKeyDocumentsCount(buildingId: any): Observable<IKeyDocumentOverview[]> {
        const url = `${this.apiBuildingBase}/${buildingId}/key-documents/count`;
        return this.http.get<any>(url)
            .pipe(
                map((response) => response),
                catchError(this.handleError)
            );
    }

    getBuildingDocumentsCount(buildingId: any): Observable<IDocumentOverview[]> {
        const url = `${this.apiBuildingBase}/${buildingId}/documents/count`;
        return this.http.get<any>(url)
            .pipe(
                map((response) => response),
                catchError(this.handleError)
            );
    }

    getBuildingKeyDocumentsByType(buildingId: string, type: KeyDocumentTypes): Observable<IKeyDocument[]> {
        const url = `${this.apiBuildingBase}/${buildingId}/${type}/key-documents`;
        return this.http.get<any>(url)
            .pipe(
                map((response) => response),
                catchError(this.handleError)
            );
    }

    getRequirementProgress(id: any): Observable<IBuildingProgress> {
        const url = `${this.apiBuildingBase}/${id}/progress`;
        return this.http.get<any>(url).pipe(
            map((response) => response),
            catchError(this.handleError)
        );
    }

    getBuildingDetails(buildingId: string) {
        const url = `${this.apiBuildingBase}/${buildingId}`;
        return this.http.get<any>(url).pipe(
            map((response) => response),
            catchError(this.handleError)
        );
    }


    uploadKeyDocuments(postModel: IUploadKeyDocumentsPostModel) {
        const formData = new FormData();
        formData.append('buildingId', postModel.buildingId);
        formData.append('userGuid', postModel.userGuid);
        postModel.files.forEach((file: File, index: number) => {
            formData.append(`uploadedKeyDocuments[${index}].documentType`, postModel.keyDocumentType.toString() ?? '');
            formData.append(`uploadedKeyDocuments[${index}].uploadedFile`, file);
        });

        const url = `${this.apiBuildingBase}/${postModel.buildingId}/key-documents`;
        return this.http.post<any>(url, formData)
            .pipe(
                map((response) => response),
                catchError(this.handleError)
            );
    }

    checkDuplicateAddress(address: string): Observable<boolean> {
        const url = `${this.apiBuildingBase}/address/check-duplicate`;
        return this.http.post<any>(url, { address }).pipe(
            map((response) => response),
            catchError(this.handleError)
        );
    }
} 
