import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { environment } from 'src/environments/environment';
import { GPTModel, IAssistantPostModel } from '../models/typings/AI/IAssistantPostModel';

@Injectable({
  providedIn: 'root',
})
export class AIApiService {
  private apiUrl = environment.apiAIBase;
  constructor(private http: HttpClient) {}

  askAssistant(postModel: IAssistantPostModel) {
    const url = `${this.apiUrl}/quippy`;
    return this.http.post<any>(url, postModel);
  }

  getAssistantInstructions(model: GPTModel) {
    const url = `${this.apiUrl}/quippy?model=${model}`;
    return this.http.get<any>(url);
  }

  analyzeESMDefectImage(file: File) {
    const formData = new FormData();

    formData.append('file', file);
    const url = `${this.apiUrl}/esm/defect/image-analysis`;
    return this.http.post<any>(url, formData);
  }
}
