export class StringHelper {
    static formatText(text: string): string {
        // Replace **text** with <strong>text</strong>
        text = text.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');
    
        // Replace \n with <br>
        text = text.replace(/\n/g, '<br>');
    
        return text;
    }
}