<div class="building-scores">
    <ul class="building-scores__links">

        <li class="link-item"
            [ngClass]="selectedSortOrder === sortOrderEnum.Ascending ? 'active' :'inactive' "
            (click)="setActiveTab(sortOrderEnum.Ascending)">
            Low
        </li>

        <li class="link-item"
            [ngClass]="selectedSortOrder === sortOrderEnum.Descending ? 'active' :'inactive' "
            (click)="setActiveTab(sortOrderEnum.Descending)">
            High
        </li>
    </ul>

    @if(!isLoading()){
    <cdk-virtual-scroll-viewport itemSize="5"
                                 class="scrollable-area qpx-thin-scrollbar">
        <div *cdkVirtualFor="let building of buildingScores(); let last = $last">
            <div class="flex wrap justify-between align-center flex-gap-md">
                <div class="flex flex-col scrollable-area__address"
                     (click)="navigateTo(building.buildingIdentifier)">
                    <div>{{ building.buildingAddress }}</div>
                    <div>{{ building.buildingCity }}</div>
                </div>

                <div class="statistics">
                    @if(type === 'integrityScore') {
                    <div class="statistics__score">{{ building.score }}</div>
                    } @else {
                    <qpx-health-rating class="mat-stars-small"
                                       [rating]="building.stars"></qpx-health-rating>
                    }
                </div>
            </div>

            @if(!last) {
            <hr class="qpx-hr-line" />
            }
        </div>
    </cdk-virtual-scroll-viewport>
    } @else {
    <qpx-loader></qpx-loader>
    }
</div>