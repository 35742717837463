import { Injectable } from '@angular/core';
import { Observable, catchError, map } from 'rxjs';

import { ApiServiceBase } from '../api-service-base';
import { IEsmDataPointsModel } from '../models';
import { ICreateEsmSchedulePostRequest } from '../models/typings/esm/create-esm-schedule-post-request.interface';
import { IGetESMDataPointsByCategoryResponse } from '../models/typings/esm/esm-get-masters-by-category-response';
import { IEsmGetRequest } from '../models/typings/esm/esm-get-request.interface';
import { GetEsmCategoriesResponse, IEsmCategory } from '../models/typings/esm/esm-get-response.interface';
import { IUpdateDefectPostRequest } from '../models/typings/esm/update-defect-post-request.interface';
import { IDefect, IUpdateEsmPostRequest } from '../models/typings/esm/update-esm-post-request.interface';

@Injectable({
    providedIn: 'root',
})
export class EsmApiService extends ApiServiceBase {
    private _apiBase = `${this.appConfig.apiBase}/api/esms`;

    getAll(model: IEsmGetRequest): Observable<IEsmCategory[]> {
        return this.http.post<any>(`${this._apiBase}/details`, model)
            .pipe(
                map((response) => response),
                catchError(this.handleError)
            );
    }

    getCategories(model: { buildingId: string; todaysDate: Date }): Observable<GetEsmCategoriesResponse[]> {
        return this.http.post<GetEsmCategoriesResponse[]>(`${this._apiBase}/category`, model)
            .pipe(
                map((response) => response),
                catchError(this.handleError)
            );
    }

    getESMDataPointsByCategory(buildingGuid: string = ''): Observable<IGetESMDataPointsByCategoryResponse[]> {
        return this.http.get<any>(`${this._apiBase}/esm-data-points-by-category?buildingId=${buildingGuid}`)
            .pipe(
                map((response) => response),
                catchError(this.handleError)
            );
    }

    updateESM(emsGuid: string, postBody: IUpdateEsmPostRequest): Observable<any> {
        const formData = new FormData();

        formData.append('auditDate', postBody.auditDate.toISOString());
        formData.append('buildingIdentifier', postBody.buildingIdentifier);

        // // Use the spread operator to append all files at once
        postBody.defects?.forEach((defect: IDefect, index: number) => {
            formData.append(`defects[${index}].description`, defect.description);
            formData.append(`defects[${index}].isCritical`, defect.isCritical.toString());
        });

        postBody.evidenceFiles.forEach((file: File) => {
            formData.append(`evidenceFiles`, file);
        });

        const url = `${this._apiBase}/audit/${emsGuid}`;
        return this.http.post<any>(url, formData)
            .pipe(
                map((response) => response),
                catchError(this.handleError)
            );
    }

    updateDefect(defectGuid: string, postBody: IUpdateDefectPostRequest): Observable<any> {
        const formData = new FormData();

        formData.append('auditDate', postBody.auditDate.toISOString());
        formData.append('buildingIdentifier', postBody.buildingIdentifier);

        postBody.evidenceFiles.forEach((file: File) => {
            formData.append(`evidenceFiles`, file);
        });

        const url = `${this._apiBase}/resolve-defect/${defectGuid}`;
        return this.http.post<any>(url, formData)
            .pipe(
                map((response) => response),
                catchError(this.handleError)
            );
    }

    createEsmSchedule(model: ICreateEsmSchedulePostRequest): Observable<any> {
        const url = `${this._apiBase}`;
        return this.http.post<any>(url, model)
            .pipe(
                map((response) => response),
                catchError(this.handleError)
            );
    }

    updateEsmSchedule(model: ICreateEsmSchedulePostRequest): Observable<any> {
        const url = `${this._apiBase}`;
        return this.http.put<any>(url, model)
            .pipe(
                map((response) => response),
                catchError(this.handleError)
            );
    }

    getDataPoints(buildingGuid: string | null = null): Observable<IEsmDataPointsModel> {
        const url = `${this._apiBase}/esm-data-points/${buildingGuid ?? ''}`;
        return this.http.get<any>(url).pipe(
            map((response) => ({
                compliantCount: response.compliantCount,
                nonCompliantCount: response.nonCompliantCount
            })),
            catchError(this.handleError)
        );
    }

}
