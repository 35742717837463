<div class="esm-donut-chart">
    <div class="qpx-card-title">Understand your Portfolio</div>

    <div *ngIf="isLoaded">
        @for(chart of charts; track chart; let last = $last){
            <div class="legend">
                <span class="legend__category-name">{{chart.categoryName}}</span>
                <div class="legend__stats">
                    <span class="bullet compliant"></span>
                    <span>Compliant</span>
                    <span class="bullet"></span>
                    <span>Not compliant</span>
                </div>
            </div>

            <apx-chart [series]="chart.chartOptions.series"
                       [chart]="chart.chartOptions.chart"
                       [labels]="labels"
                       [responsive]="chart.chartOptions.responsive"
                       [legend]="chart.chartOptions.legend"
                       [dataLabels]="chart.chartOptions.dataLabels"
                       [noData]="chart.chartOptions.noData"
                       [colors]="chart.chartOptions.colors"
                       [plotOptions]="chart.chartOptions.plotOptions"
                       [tooltip]="chart.chartOptions.tooltip">
            </apx-chart>

            @if(!last) {
                <hr class="qpx-hr-line divider" />
            }
        }
    </div>

    <qpx-loader *ngIf="!isLoaded"></qpx-loader>
</div>

